import React from 'react'
import { graphql } from 'gatsby'
import T from 'prop-types'

import SEO from '../../components/SEO'
import Articles from '../../components/Articles'

export default function Page({
  data: {
    foodsteps: { page },
  },
}) {
  return (
    <>
      <SEO
        title="Artikler"
        description="Artikler og guider om mat og vin, fra kokkene bak Foodsteps og Kulinarisk Akademi."
      />
      <Articles sections={page.sections} />
    </>
  )
}

Page.propTypes = {
  data: T.shape({
    foodsteps: T.shape({
      page: T.object.isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query {
    foodsteps {
      page(id: "articles") {
        id
        ... on FS_ArticlesPage {
          id
          sections {
            id
            type
            ... on FS_ArticlePromotion {
              title
              articles {
                edges {
                  node {
                    id
                    image {
                      lqip
                      url
                      asset {
                        metadata {
                          dimensions {
                            width
                            height
                          }
                        }
                      }
                    }
                    title
                    slug
                  }
                }
              }
            }
            ... on FS_ArticleCategoryPromotion {
              category {
                node {
                  id
                  articles {
                    edges {
                      node {
                        id
                        image {
                          url
                          lqip
                          asset {
                            metadata {
                              dimensions {
                                width
                                height
                              }
                            }
                          }
                        }
                        title
                        slug
                        preamble
                      }
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
