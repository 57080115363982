import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Link } from 'gatsby'

import { Container, AspectRatioImage } from '@components'

const bem = new BEMHelper('articles')

const TYPES = {
  1: 'single',
  2: 'double',
  3: 'tripple',
}

export default function Articles({ sections }) {
  return (
    <Container {...bem('')}>
      <h1 {...bem('title', 'large')}>
        Artikler og guider om mat og vin, fra kokkene bak Foodsteps og
        Kulinarisk Akademi.
      </h1>

      {sections
        .filter(({ articles }) => articles && articles.edges.length > 0)
        .map(({ id, articles }) => {
          const type = TYPES[articles.edges.length]

          return (
            <div {...bem('section')} key={id}>
              <nav {...bem('list', type)}>
                {articles.edges.map(({ node: { id, title, slug, image } }) => {
                  const { width, height } = image.asset.metadata.dimensions
                  const wide = type === 'single' && width > height

                  return (
                    <Link
                      key={id}
                      to={`/artikler/${slug}`}
                      {...bem('item', { [type]: type, wide })}
                    >
                      {wide ? (
                        <img src={image.url} {...bem('image')} alt="" />
                      ) : (
                        <AspectRatioImage
                          {...bem('image')}
                          {...image}
                          src={image.url}
                          aspect="3/4"
                        />
                      )}

                      <h2 {...bem('title')}>{title}</h2>
                    </Link>
                  )
                })}
              </nav>
            </div>
          )
        })}
    </Container>
  )
}

Articles.propTypes = {
  sections: T.array.isRequired,
}
